@import url("https://fonts.googleapis.com/css?family=Roboto:400,700|Outfit:700|inter:500");

:root{
    --black-2: rgba(0,0,0,0.698);
    --black:rgba(0,0,0,1);
    --astronaut-blue:rgba(10,58,98,1);
    --hawkes-blue:rgba(205,228,251,1);
    --alto: rgba(217,217,217,0.6);

    --font-size-xxxs:13px;
    --font-size-xxs:14px;
    --font-size-xs:15px;
    --font-size-s:16px;
    --font-size-m:20px;
    --font-size-l:56px;
    --font-size-xl:60px;

    --font-family-inter:"Inter";
    --font-family-outfit:"Outfit";
    --font-family-roboto:"Roboto"

}
a:link {
    color: rgb(34, 68, 180);
    background-color: transparent;
    text-decoration: none;
  }
.roboto-normal-black-20px-2{
    color: var(--black-2);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
}
.inter-medium-black-13px{
    color:var(--black);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xxxs);
    font-weight: 500;
    font-style: normal;
}
.roboto-normal-black-20px{
    color:var(--black);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
}
.roboto-bold-astronaut-blue-60px{
    color:var(--astronaut-blue);
    font-family: var(--font-family-outfit);
    font-size: var(--font-size-xl);
    font-weight: 700;
    font-style: normal;
}

/**/
.menu-item-1{
    align-items: flex-start;
    display: flex;
    height: 28px;
    min-width: 95px;
}
.menu-item {
    letter-spacing: 0;
    line-height: 27.5px;
    margin-top: -1px;
    min-height: 28px;
    white-space: nowrap;
}
.menu-item-1.menu-item-2{
    margin-left: 35px;
    min-width: 98px;
}
.menu-item-1.menu-item-3{
    margin-left: 34px;
    min-width: 82px;
}
.sign-in {
    align-items: flex-start;
    display: flex;
    height: 28px;
    margin-left: 111px;
    min-width: 197px;
}
.sign-in-1 {
    color: var(--astronaut-blue);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 700;
    letter-spacing: 0;
    line-height: 27.5px;
    margin-top: -1px;
    min-height: 28px;
    white-space: nowrap;
}


.up{
    align-items: center;
    display: flex;
    height: 174px;
    /* min-width: 1262px; */
}


.innorma-6-1{
    align-self: flex-end;
    height: 146px;
    object-fit: cover;
    width: 174px;
}
.animation {
    align-items: flex-start;
    display: flex;
    height: 28px;
    margin-left: 436px;
    margin-top: 18px;
    /* min-width: 652px; */
    position: relative;
}

.overlap-group {
    height: 1078px;
    margin-top: 37px;
    position: relative;
    width: 1289px;
}
.x2-1{
    height: 850px;
    left: 174px;
    object-fit: cover;
    position: absolute;
    top: 228px;
    width: 901px;
}
.line-1{
    height: 32px;
    left: 323px;
    position: absolute;
    top: 350px;
    width: 960px;
}
.x4-1 {
    height: 621px;
    left: 773px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 600px;
}
.image{
    height: 198px;
    left: 0;
    position: absolute;
    top: 143px;
    width: 777px;
}
.create-your-product{
    left: 39px;
    letter-spacing: 0;
    line-height: 66px;
    position: absolute;
    top: 149px;
    width: 678px;
}
.image {
    height: 199px;
    left: 440px;
    position: absolute;
    top: 137px;
    width: 764px;
    
}


@media (max-width: 530px) {
    
    .innorma-6-1{
        position: absolute;
        height: 150px;
        width: 180px;
        left: 105px;
        top: 10px;
       
        
       
    }
    .sign-in {
        
        display: none;
       
    }
    .sign-in-1 {
        display: none;
       
    }
    .menu-item-1{
        position: relative;
        right: 410px;
        top: 70px;
        
    }
    .up{
        align-items: center;
        display: flex;
        height: 20vh;
        /* min-width: 1262px; */
    }
    .menu-item {
        font-size: 100px;
    }
}