.foot {
    /* display: inline-block; */
    background-color: #d9d9d9cc;
    height: 160px;
    left:0;
    position: absolute;
    top: 4800px;
    width:100%;
}
.innorma {
    color: #062a5b;
    font-family: var(--font-family-outfit);
    font-size: var(--font-size-xxs);
    font-weight: 700;
    left: 19px;
    letter-spacing: 0;
    line-height: 66px;
    /* position: absolute; */
    text-align: center;
    text-decoration: underline;
    white-space: nowrap;
    /* width: 235px; */

}
.phone {
    color: var(--black);
    font-family: var(--font-family-outfit);
    font-size: var(--font-size-xxs);
    font-weight: 700;
    left: 19px;
    letter-spacing: 0;
    /* position: absolute; */
    text-align: center;
    text-decoration: underline;
    white-space: nowrap;
    /* width: 235px; */
}
.copy {
    color: rgb(112, 112, 112);
    font-family: var(--font-family-outfit);
    font-size: var(--font-size-xxs);
    font-weight: 700;
    left: 19px;
    letter-spacing: 0;
    /* position: absolute; */
    text-align: center;
    /* text-decoration: underline; */
    white-space: nowrap;
    /* width: 235px; */
}
.language {
    color: var(--black);
    font-family: var(--font-family-outfit);
    font-size: var(--font-size-xxs);
    font-weight: 700;
    left: 19px;
    letter-spacing: 0;
    /* position: absolute; */
    text-align: center;
    white-space: nowrap;
    /* width: 235px; */
    /* padding: ; */
    border: none;
    background: none;
}

@media (max-width: 530px) {
    .foot{
        
        top: 3800px;
        background-color: #ffffffcc;
    }
    .copy{
        transform: scale(0.7);
        width: 30vw;
    }

}