//darkly
// $primary:#543c91;
// //$secondary:#534d53;
// $secondary:#50474d;
// $info:#000000;
// $light: #c0c0c0;
$danger:#8a0101;

$primary:#061a4b;
//$secondary:#534d53;
//$secondary:#b69fae;
$secondary:#cde0f1;

$info:#253648;
$light: #e4e4e4;
$black:#0f161d;
$dark:$black;

$input-bg: #ecf1f5;
//$table-white:rgb(250, 219, 198);
// $muted:rgb(194, 255, 202);
// $white:rgb(250, 219, 198);
// $navbar-dark-color: $secondary;
// $navbar-dark-hover-color: $secondary;
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";

// $custom-colors: (
    
//     "aaa": #007f2e,
    
//     "bbb": #00B2CE,
// );

// $theme-colors: map-merge($theme-colors, $custom-colors);



@import "~bootswatch/dist/flatly/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/flatly/bootswatch";
// $table-white:rgb(250, 219, 198);
// $custom-colors: (
//   "orange": rgb(250, 219, 198),
//   "green" : rgb(136, 255, 156)

// );


@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@700&display=swap');
:root{
    --bs-body-font-family:'Oxygen', sans-serif;

}


//flatly, cosmo, sandstone, pulse, united
