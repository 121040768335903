.about {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    left: 42px;
    min-height: 756px;
    position: absolute;
    top: 1289px;
    width: 1081px;
    /* opacity: 0.1; */
}
.why-innorma {
    align-self: center;
    letter-spacing: 0;
    line-height: 66px;
    margin-right: 39px;
    min-height: 66px;
    text-align: center;
    white-space: nowrap;
    width: 678px;
}
.flex-row {
    align-items: flex-start;
    display: flex;
    height: 599px;
    margin-top: 91px;
    min-width: 1071px;
}
.untitled-design-3-1{
    height: 555px;
    margin-top: 4px;
    object-fit: cover;
    width: 445px;
}
.group-2{
    align-items: flex-start;
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-left: 45px;
    min-height: 529px;
    width: 17px;
}
.ellipse-5{
    background-color: var(--hawkes-blue);
    border-radius: 8.5px;
    height: 17px;
    width: 17px;
}
.ellipse-6{
    background-color: var(--hawkes-blue);
    border-radius: 8.5px;
    height: 17px;
    margin-top: 141px;
    width: 17px;
}
.ellipse-7{
    background-color: var(--hawkes-blue);
    border-radius: 8.5px;
    height: 17px;
    margin-top: 175px;
    width: 17px;
}
.ellipse-8{
    background-color: #a3a9e199;
    border-radius: 8.5px;
    height: 17px;
    margin-top: 145px;
    width: 17px;
}
.flex-col{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    min-height: 599px;
    width: 550px;
}

.roboto-normal-black-20px {
    color: var(--black);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
}
.we-make-process-of-c{
    letter-spacing: 0;
    line-height: 27.5px;
    min-height: 88px;
    width: 540px;
}
.we-manage-every-elem {
    letter-spacing: 0;
    line-height: 27.5px;
    margin-top: 42px ;
    min-height: 177px;
    width: 540px;
}
.upload-your-chromato {
    letter-spacing: 0;
    line-height: 27.5px;
    margin-top: 10px ;
    min-height: 143px;
    width: 550px;
}
.you-will-have-your-c{
    letter-spacing: 0;
    line-height: 27.5px;
    margin-top: 51px ;
    min-height: 88px;
    width: 540px;
}



@media (max-width: 530px) {
    .about{
        transform: scale(0.7);
        left: -355px;
        /* top:710px; */
        top:800px;
        
        
        font-size: 100%
    }

    .why-innorma {
        transform: scale(0.9);
        position: absolute;
        right:150px;
        top:-20px;
    }
    .untitled-design-3-1{
        display: none;
    }
    .flex-col{
        transform: scale(1);
        display: flex;
        width: 100vw;
        
        flex-direction: column;
        align-items: top; 
    }
    .we-make-process-of-c{
        width: 120vw;
        
    }
    .we-manage-every-elem {
        width: 120vw;
        
    }
    .upload-your-chromato {
        width: 120vw;
        
    }
    .you-will-have-your-c{
        width: 120vw;
        
    }
   
}