@import url("https://fonts.googleapis.com/css?family=Roboto:400,700|Outfit:700|inter:500");

:root{
    --black-2: rgba(0,0,0,0.698);
    --black:rgba(0,0,0,1);
    --astronaut-blue:rgba(10,58,98,1);
    --hawkes-blue:rgba(205,228,251,1);
    --alto: rgba(217,217,217,0.6);

    --font-size-xxxs:13px;
    --font-size-xxs:14px;
    --font-size-xs:15px;
    --font-size-s:16px;
    --font-size-m:20px;
    --font-size-l:56px;
    --font-size-xl:60px;

    --font-family-inter:"Inter";
    --font-family-outfit:"Outfit";
    --font-family-roboto:"Roboto"

}


.overlap-group-11 {
    height: 676px;
    margin-top: 154px;
    position: relative;
    width: 1000px;
}
.x865465-1 {
    height: 593px;
    left: 499px;
    object-fit: cover;
    position: absolute;
    top: 83px;
    width: 890px;
}
.overlap-group-9 {
    align-items: flex-start;
    background-color: #a3a9e15c;
    border-radius: 109px;
    display: flex;
    flex-direction: column;
    left: 188px;
    min-height: 512px;
    padding: 27px 13px;
    position: absolute;
    top: 0;
    width: 705px;
}
.try-it-for-free {
    align-self: flex-end;
    color: var(--astronaut-blue);
    font-family: var(--font-family-outfit);
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 66px;
    margin-top: 12px;
    min-height: 66px;
    text-align: center;
    white-space: nowrap;
    width: 678px;
}
.leave-a-request-and {
    align-self: center;
    color: var(--black-2);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-s);
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27.5px;
    margin-left: 15px;
    min-height: 28px;
    text-align: center;
    white-space: nowrap;
    width: 384px;
}
.overlap-group3-1 {
    height: 29px;
    margin-left: 62px;
    margin-top: 45px;
    position: relative;
    width: 343px;
}
.agree {
    color: var(--black-2);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-xs);
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 27.5px;
    text-align: center;
    top: 0;
    width: 384px;
}
.apply-a-request {
    color: #ffffff;
    font-family: var(--font-family-outfit);
    font-size: var(--font-size-m);
    font-weight: 700;
    /* left: 0; */
    position: relative;
    letter-spacing: 0;
    line-height: 66px;
    text-align: center;
    top: 0;
    white-space: nowrap;
    width: 150px;
}
.overlap-group-100 {
    align-self: center;
    height: 62px;
    margin-left: 1px;
    margin-top: 10px;
    position: relative;
    width: 642px;
}
.cardo {
    background-color: rgb(9, 9, 70);
    color: azure;
    width: 20px;
    height: 35px;
    left: 20px;
    margin-top: 10px;
    position: relative;
    align-self: center;
    text-align: center;
    /* display: 'flex'; */


}
.cardo1 {
    font-family: var(--font-family-roboto);

}

@media (max-width: 530px) {
    .overlap-group-11{
        transform: scale(0.8);
        left: -350px;
        top:-1050px;

    }
    .x865465-1{
        display: none;
    }
    .overlap-group3-1 {
        left: 100px;

    }



}