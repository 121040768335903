.screen a {
    display: contents;
    text-decoration: none;
}
html{
    overflow-x:hidden;
    

}
.overlap-group10{
    align-self: flex-end;
    height: 2045px;
    margin-right: -398px;
    margin-top: -28px;
    position: relative;
    width: 1000px;
}
.ready {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    left: 0;
    min-height: 1289px;
    position: absolute;
    top: 0;
    width: 1000px;
}
.desktop-4 {
    align-items: flex-start;
    background-color: #ecf1f5;
    display: flex;
    height: 4928px;
    overflow: hidden;
    position: relative;
    width: 1000px;
    flex-direction: column;
}
.container-center-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    pointer-events: none;
    width: 100%;
}
.container-center-horizontal > * {
    flex-shrink: 0;
    pointer-events: auto;
}
.screenshot-130-1{
    height: 104px;
    left: 603px;
    position: absolute;
    top: 392px;
    width: 313px;
}
.outfit-bold-astronaut-blue-60px{
    color: var(--astronaut-blue);
    font-family: var(--font-family-outfit);
    font-size: var(--font-size-xl);
    font-weight: 700;
    font-style: normal;
}
#fader0{
    display: none;
}
#fader01{
    display: none;
}

/* .innormagmailcom {
    color: #062a5b;
    font-family: var(--font-family-outfit);
    font-size: var(--font-size-xxs);
    font-weight: 700;
    left: 19px;
    letter-spacing: 0;
    line-height: 66px;
    position: relative;
    text-align: center;
    text-decoration: underline;
    top: 556px;
    white-space: nowrap;
    width: 235px;
}
.phone {
    color: var(--black);
    font-family: var(--font-family-outfit);
    font-size: var(--font-size-xxs);
    font-weight: 700;
    left: 36px;
    letter-spacing: 0;
    line-height: 66px;
    position: relative;
    text-align: center;
    top: 585px;
    white-space: nowrap;
    width: 166px;
} */
@media (max-width: 530px) {
    
    
    body{
        position: relative;
        margin-left:auto;
        margin-right:auto;
        text-align:"center";
        
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        overflow-x:hidden;
        
    }

    .screenshot-130-1{
        left:20px;
        transform: scale(2);
        
        

       
    }
    .x2-1{
        left:-300px;
        transform: scale(-1,1);

    }
    .line-1{
        top:70px;
        transform: scale(-1,1);
        left:-230px;


    }
    .x4-1{
        left:500px;
        top: 550px;
        transform: scale(1.5);
        
        
        

    }
    .overlap-group{
        bottom: 40px;
        transform: scale(0.5);
        width: 10px;
        height: 0vh;
        overflow-x:none;
    }
    #fader0{
        display:flex;
        top: 39.8vh;
        left: 150px;
        width: 300px;
        height: 500px;
        background: linear-gradient(to right, rgba(255,255,255,0) 30%,rgb(255, 255, 255) 100%);
        position: absolute;
    }
    #fader01{
        display:flex;
        top: 320px;
        left: 150px;
        width: 300px;
        height: 500px;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 30%,rgb(255, 255, 255) 100%);
        position: absolute;
    }
    .container-center-horizontal{
        height: 4290px;
        
    }
    
   
    



}


.create-your-product{
    top:100px;
}
