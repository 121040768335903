.print-source {
    display: none;
}

h1 {
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    text-align:center;

}

@media print {
    .print-source {
      display: block;
    }
    /* .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      } */
}