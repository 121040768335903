@import url("https://fonts.googleapis.com/css?family=Roboto:400,700|Outfit:700|inter:500");

:root{
    --black-2: rgba(0,0,0,0.698);
    --black:rgba(0,0,0,1);
    --astronaut-blue:rgba(10,58,98,1);
    --hawkes-blue:rgba(205,228,251,1);
    --alto: rgba(217,217,217,0.6);

    --font-size-xxxs:13px;
    --font-size-xxs:14px;
    --font-size-xs:15px;
    --font-size-s:16px;
    --font-size-m:20px;
    --font-size-l:56px;
    --font-size-xl:60px;

    --font-family-inter:"Inter";
    --font-family-outfit:"Outfit";
    --font-family-roboto:"Roboto"

}


.view{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: -71px;
    margin-top: 68px;
    min-height: 2013px;
    width: 1338px;
}
.overlap-group-container {
    height: 536px;
    margin-left: 1px;
    position: relative;
    width: 1277px;
}
.overlap-group-4 {
    height: 0;
    left: 0;
    position: relative;
    top: 200px;
    width: 1277px;
}
.for-creating-your-ne {
    left: 440px;
    letter-spacing: 0;
    line-height: 27.5px;
    position: absolute;
    top: 44px;
    width: 732px;
}
.roboto-normal-black-20px {
    color: var(--black);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
}
.text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    left: 330px;
    min-height: 183px;
    position: absolute;
    top: 145px;
    width: 947px;
}
.inter-medium-black-13px{
    color:var(--black);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xxxs);
    font-weight: 500;
    font-style: normal;
}
.flex-row {
    align-items: flex-start;
    align-self: flex-end;
    display: flex;
    margin-right: 36px;
    min-width: 791px;
}
.lips {
    height: 16px;
    letter-spacing: 0px;
    min-width: 26px;
    text-align: center;
}
.valign-text-middle{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.axillae {
    height: 16px;
    letter-spacing: 0.65px;
    margin-left: 25px;
    text-align: center;
    width: 60px;
}
.navbar-link-container {
    height: 16px;
    margin-left: 3px;
    position: relative;
    width: 677px;
}
.on-face-using-fingertips {
    height: 16px;
    left: 0;
    letter-spacing: 0.39px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 200px;
}
.fine-fragance {
    height: 16px;
    left: 155px;
    letter-spacing: 0.39px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 200px;
}
.face-moisturizer {
    height: 16px;
    left: 477px;
    letter-spacing: 0.39px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 200px;
}
.body-lotion-using-hands {
    height: 16px;
    left: 312px;
    letter-spacing: 0.39px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 200px;
}
.flex-row-1{
    align-items: flex-start;
    align-self: flex-end;
    display: flex;
    margin-right: 36px;
    min-width: 791px;
}
.overlap-group2{
    height: 16px;
    margin-left: 3px;
    position: relative;
    width: 677px;
}
.face-mosturizer{
    height: 16px;
    left: 477px;
    letter-spacing: 0.39px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 200px;
}
.flex-row-2 {
    align-items: flex-start;
    align-self: center;
    display: flex;
    margin-right: 24px;
    margin-top: 26px;
    min-width: 683px;
}
.oral-and-lip-exposure{
    height: 16px;
    letter-spacing: 0.65px;
    text-align: center;
    width: 145px;
}
.rinse-off-hair {
    height: 16px;
    letter-spacing: 0;
    margin-left: 31px;
    min-width: 87px;
    text-align: center;
}
.leave-on-hair {
    height: 16px;
    letter-spacing: 0.39px;
    margin-left: 31px;
    min-width: 92px;
    text-align: center;
}
.anogenital {
    height: 16px;
    letter-spacing: 0.39px;
    margin-left: 31px;
    min-width: 71px;
    text-align: center;
}
.body-and-hand-rinse-off {
    height: 16px;
    letter-spacing: 0.39px;
    margin-left: 34px;
    min-width: 161px;
    text-align: center;
}

.overlap-group1 {
    height: 13px;
    margin-top: 10px;
    position: relative;
    width: 785px;
}
.skin-contact-with-mi{
    height: 12px;
    left: 348px;
    position: absolute;
    text-align: center;
    top: 1px;
    width: 437px;
}
.span0 {
    letter-spacing: 0.39px;
}
.span1 {
    letter-spacing: 0.52px;
}
.household-care {
    height: 12px;
    left: 0;
    letter-spacing: 0.39px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 437px;
}
.skin-contact-container {
    align-self: center;
    height: 13px;
    margin-left: 54px;
    margin-top: 28px;
    position: relative;
    width: 787px;
}
.skin-contact-with-mi-1 {
    height: 16px;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 437px;
}
.not-intendedminimal-skin-contact {
    height: 12px;
    left: 350px;
    letter-spacing: 0.39px;
    position: absolute;
    text-align: center;
    top: 1px;
    width: 437px;
}
.overlap-group-2 {
    height: 13px;
    margin-left: 12px;
    margin-top: 31px;
    position: relative;
    width: 739px;
    
}
.household-care {
    height: 12px;
    left: 0;
    letter-spacing: 0.39px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 437px;
}
.hand-cream {
    height: 12px;
    left: 189px;
    letter-spacing: 0.39px;
    position: absolute;
    text-align: center;
    top: 1px;
    width: 437px;
}
.baby-creams {
    height: 12px;
    left: 302px;
    letter-spacing: 0.39px;
    position: absolute;
    text-align: center;
    top: 1px;
    width: 437px;
}
.image-1 {
    height: 92px;
    left: 973px;
    object-fit: cover;
    position: absolute;
    top: 213px;
    width: 80px;
}
:root {
    --alto: rgba(217,217,217,0.6);
    --astronaut-blue: rgba(10,58,98,1);
    --font-family-outfit: "Outfit"
}
.ellipse-1 {
    background-color: var(--alto);
    border-radius:  82.5px/76.5px;
    height: 153px;
    left: 259px;
    position: absolute;
    top: 0;
    width: 165px;
}
.number {
    left: 0;
    letter-spacing: 0;
    line-height: 66px;
    position: absolute;
    text-align: center;
    top: 42px;
    width: 678px;
}
.overlap-group-6 {
    height: 206px;
    left: 445px;
    position: absolute;
    top: 0;
    width: 678px;
}
.x2 {
    height: 206px;
    left: 63px;
    position: absolute;
    top: 0;
    width: 191px;
}
.title {
    color: var(--astronaut-blue);
    font-family: var(--font-family-outfit);
    font-size: var(--font-size-l);
    align-self: center;
    letter-spacing: 0;
    line-height: 66px;
    margin-right: 39px;
    min-height: 66px;
    position: absolute;
    text-align: center;
    white-space: nowrap;
    width: 678px;
}
.flex-row-3 {
    align-items: center;
    align-self: flex-end;
    display: flex;
    margin-right: 14px;
    /* margin-top: 62px; */
    min-width: 864px;
}
.then-upload-you-ch {
    letter-spacing: 0;
    line-height: 27.5px;
    margin-top: 18px;
    min-height: 69px;
    text-align: right;
    width: 682px;
}
.overlap-group-7 {
    align-items: center;
    background-color: var(--alto);
    border-radius: 82.5px/76.5px;
    display: flex;
    height: 153px;
    justify-content: flex-end;
    margin-left: 17px;
    min-width: 165px;
    padding: 0 19px;
}
.number-1 {
    letter-spacing: 0;
    line-height: 66px;
    min-height: 65px;
    text-align: center;
    white-space: nowrap;
    width: 126px;
}
.image-2-1 {
    align-self: flex-end;
    height: 398px;
    margin-right: 88px;
    margin-top: 52px;
    object-fit: cover;
    width: 750px;
}
.overlap-group-5 {
    height: 716px;
    margin-top: 96px;
    position: relative;
    width: 1308px;
}
.ellipse-5-1 {
    background-color: var(--alto);
    border-radius: 82.5px/76.5px;
    height: 153px;
    left: 260px;
    position: absolute;
    top: 0;
    width: 165px;
}
.finally-save-your-f {
    left: 440px;
    letter-spacing: 0;
    line-height: 27.5px;
    position: absolute;
    top: 42px;
    width: 705px;
}
.number {
    left: 0;
    letter-spacing: 0;
    line-height: 66px;
    position: absolute;
    text-align: center;
    top: 42px;
    width: 678px;
}
.screenshot-129-1 {
    height: 433px;
    left: 695px;
    object-fit: cover;
    position: absolute;
    top: 283px;
    width: 613px;
}
.x3-1 {
    height: 450px;
    left: 440px;
    position: absolute;
    top: 132px;
    width: 314px;
}
#fader1{
    display: none;
}
#fader2{
    display: none;
}


@media (max-width: 530px) {
    body{
        width: 100vw;
    }
    .title{
        transform: scale(0.7);
        top:-100px;
        left:125px
    }
    
    .ellipse-1{
        position: absolute;
        left: 450px;
        top:-120px;
      
        
    }
    
    #firstNumber{
        left: 190px;
        top:-75px;

    }
    .overlap-group-container{
        display: flex;
        width: 100vw;
        transform: scale(0.7);
        top:-120px;
        left:-80px;
       
        
        flex-direction: column;
        align-items: top; 
    }
    .for-creating-your-ne{
        width: 130vw;
        margin: 20px;
        left: 415px;

    }
    #fader1{
        display:flex;
        top: 170px;
        left: 650px;
        width: 400px;
        height: 300px;
        background: linear-gradient(to right, rgba(255,255,255,0) 30%,rgba(255,255,255,1) 100%);
        position: absolute;
    }
    .image{
        transform: scale(0.9);
        top:170px;
        left:390px;
        /* background-image:linear-gradient(to left , #ffffff, rgba(0,128,128,0) 400px); */
        /* box-shadow: 0px 0px 25px 25px rgba(55,54,51, 1); */
    
    

    }
    
    .text{
        transform: scale(0.9);
        top:178px;
        left:280px;

    }

    .flex-row-3 {
        display: flex;
        position: absolute;
        width: 100vw;
        transform: scale(0.7);
        top:480px;
        left:160px; 
        margin:0px;
       
        
        /* flex-direction: column;
        align-items: top;  */
    }
    .image-2-1 {
        transform: scale(0.8);
        position: absolute;
        left:200px;
        top:520px;
        

    }
    .overlap-group-7{
       
        position: absolute;
        top:-160px;
        left:300px;
    }
    .then-upload-you-ch {
        width: 130vw;
        
    }
    #fader2{
        display:flex;
        top: 570px;
        left: 400px;
        width: 100vw;
        height: 1000px;
        background: linear-gradient(to right, rgba(255,255,255,0) 30%,rgb(255, 255, 255) 100%);
        position: absolute;
    }
    .overlap-group-5 {
        
        transform: scale(0.7);
        top:310px;
        left:-210px;

    }
    .ellipse-5-1 {
        top: -140px;
        left: 430px;
    }
    #thirdNumber {
        top: -93px;
        left: 175px;

    }
    .finally-save-your-f{
        width: 130vw;
        border-bottom: 100px;
    }
    .x3-1{
        top: 162px;

    }
    .view{
        position:relative;
        top:-600px;
    }
   
    
}