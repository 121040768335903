@media all {
  .page-break {
    display: none;
    
  }
}


@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    font-size: 12px;
  }

  
  footer {
    /* position: fixed; */
    bottom: 0;
    font-size: 10px;
    text-align: center;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
  /* div.page-footer {
    position: fixed;
    z-index: 9;
    bottom: 0;
    width: 100%;
    height: 50px;
    font-size: 15px;
    color: rgb(0, 0, 0);
    opacity: 0.5;
    page-break-after: always;
  } */
  /* .tv {
    margin: 15mm 15mm 52mm 11mm;
    
  } */
  
}

@page {
  size: A4;
  margin: 11mm 15mm 15mm 15mm;
}